import React, { useState } from 'react';
import { NavBar } from './NavBar';
import { SelectedStep } from './StepsSection/SelectedStep';
import './App.css';
import mobile from './images/banner_svg/Banner Mobile 2(Tristan Thomas).svg';
import tablet from './images/banner_svg/Banner Tablet (Tristan Thomas).svg';
import desktop from './images/banner_svg/Banner (Tristan Thomas).svg';

function App() {
  const [activeStep, setActiveStep] = useState(1);

  const onClickNext = () => {
    const updatedStep = activeStep <= 5 ? activeStep + 1 : activeStep;
    setActiveStep(updatedStep);
  }

  return (
    <div>
         <div className="responsive-image">
          <img src={mobile} alt="Mobile Banner" className="mobile" />
          <img src={tablet} alt="Tablet Banner" className="tablet" />
          <img src={desktop} alt="Desktop Banner" className="desktop" />
        </div>
        <div className='login'></div>
        <div className="container">
          <NavBar activeStep={activeStep} />
          <div className="content">
            <SelectedStep activeStep={activeStep} onClickNext={onClickNext} />
          </div>
        </div>
    </div>
  );
}

export default App;
