// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  margin: 0;
  padding: 0;
  font-family: Lato, "Open Sans", sans-serif;
  background-color: #fff; /* Ensures background consistency */
}

.container {
  max-width: 800px; /* Adjust based on your design preference */
  margin: 0 auto; /* Centers the container */
  text-align: center;
  padding: 0px 0; /* Provides padding at the top and bottom */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
}
.login{
  padding-left: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}


@media (min-width: 600px) {
  .mobile {
    display: none;
  }
  .tablet {
    display: block; 
    width: 100%;
    
  }
}

@media (min-width: 1024px) {
  .tablet {
    display: none; 
  }
  .desktop {
    display: block; 
    width: 100%;
    
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,0CAA0C;EAC1C,sBAAsB,EAAE,mCAAmC;AAC7D;;AAEA;EACE,gBAAgB,EAAE,2CAA2C;EAC7D,cAAc,EAAE,0BAA0B;EAC1C,kBAAkB;EAClB,cAAc,EAAE,2CAA2C;AAC7D;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;AACrB;;;AAGA;EACE;IACE,aAAa;EACf;EACA;IACE,cAAc;IACd,WAAW;;EAEb;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,cAAc;IACd,WAAW;;EAEb;AACF","sourcesContent":["body, html {\n  margin: 0;\n  padding: 0;\n  font-family: Lato, \"Open Sans\", sans-serif;\n  background-color: #fff; /* Ensures background consistency */\n}\n\n.container {\n  max-width: 800px; /* Adjust based on your design preference */\n  margin: 0 auto; /* Centers the container */\n  text-align: center;\n  padding: 0px 0; /* Provides padding at the top and bottom */\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-height: 60vh;\n}\n.login{\n  padding-left: auto;\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 20px;\n  color: white;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  margin-bottom: 20px;\n}\n\n\n@media (min-width: 600px) {\n  .mobile {\n    display: none;\n  }\n  .tablet {\n    display: block; \n    width: 100%;\n    \n  }\n}\n\n@media (min-width: 1024px) {\n  .tablet {\n    display: none; \n  }\n  .desktop {\n    display: block; \n    width: 100%;\n    \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
