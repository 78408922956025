// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.circle {
  width: 50px; 
  height: 50px;
  border-radius: 50%;
  background-color: white;
  color: #c8d3d8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border: 2px solid #c8d3d8;
}

.circle.disabled {
  border-color: #cccccc;
  background-color: #edf6f9;
  color: #cccccc;
}

.circle.active {
  background-color: #4eabe9;
  border-color: black;
  color: white;
}

.mobile, .tablet, .desktop {
  display: none; 
}
.mobile {
  display: block; 
  width: 100%;
  
}
/* .login{
  background-color: black;
  padding-left: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}


@media (min-width: 600px) {
  .mobile {
    display: none;
  }
  .tablet {
    display: block; 
    width: 100%;
    
  }
}

@media (min-width: 1024px) {
  .tablet {
    display: none; 
  }
  .desktop {
    display: block; 
    width: 100%;
    
  }
} */

`, "",{"version":3,"sources":["webpack://./src/NavBar/NavBarStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;AACA;EACE,cAAc;EACd,WAAW;;AAEb;AACA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAiCG","sourcesContent":[".progress-bar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 2rem;\n  padding: 0 2rem;\n}\n\n.circle {\n  width: 50px; \n  height: 50px;\n  border-radius: 50%;\n  background-color: white;\n  color: #c8d3d8;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 24px;\n  border: 2px solid #c8d3d8;\n}\n\n.circle.disabled {\n  border-color: #cccccc;\n  background-color: #edf6f9;\n  color: #cccccc;\n}\n\n.circle.active {\n  background-color: #4eabe9;\n  border-color: black;\n  color: white;\n}\n\n.mobile, .tablet, .desktop {\n  display: none; \n}\n.mobile {\n  display: block; \n  width: 100%;\n  \n}\n/* .login{\n  background-color: black;\n  padding-left: auto;\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 20px;\n  color: white;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  margin-bottom: 20px;\n}\n\n\n@media (min-width: 600px) {\n  .mobile {\n    display: none;\n  }\n  .tablet {\n    display: block; \n    width: 100%;\n    \n  }\n}\n\n@media (min-width: 1024px) {\n  .tablet {\n    display: none; \n  }\n  .desktop {\n    display: block; \n    width: 100%;\n    \n  }\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
