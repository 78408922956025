import { NextButton } from '../../NextButton';
import { CATEGORIES_OPTIONS } from './categoriesOptions';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategories } from './SelectCategoriesSlice';
import './SelectCategories.css';

export const SelectCategories = ({ onClickNext }) => {
  const selectedValues = useSelector((state) => state.categories.selectedValues);
  const dispatch = useDispatch();

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    dispatch(setSelectedCategories({name, checked}));
  };

  return (
    <div className="select-categories-container">
      <h2 className="select-categories-title">DIBBs Daily Opportunity Questionnaire</h2>
      <p className="select-categories-subtext">What categories would you like to pull from? (Check All That Apply)</p>
      <form className="checkbox-list">
        {CATEGORIES_OPTIONS.map((category, index) => (
          <label key={index} className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              className="checkbox-item-input"
              id={category.code}
              name={category.code}
            />
            {category.label}
            <img className="checkbox-item-image" src={category.image} alt="" height={20} />
          </label>
        ))}
      </form>
      <NextButton buttonText="Move to Step 3 >" handleClick={onClickNext} disabled={Object.values(selectedValues).filter(value => !!value).length < 1} />
    </div>
  )
}