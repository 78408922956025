// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-form-container {
  width: 80%;
  padding-bottom: 5rem;
}

.email-form-title {
  margin-bottom: 1rem;
  font-family: 'Open Sans', sans-serif;
}

.email-form-subtext {
  margin-top: 0;
  font-size: 16px;
  color: #6B6B6B;
  font-family: "Lato", sans-serif;
  margin-bottom: 1.5rem;
  padding: 0 5rem;
}

.email-form-input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 15px;
  border: 1px solid #4eabe9;
  border-radius: 4px;
  min-height: 40px;
  font-size: 16px;
  font-weight: 500;
}


/* Mobile Styling */


@media (max-width: 480px) {
  .email-form-subtext{
    padding: 0 .8rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/StepsSection/EmailForm/EmailForm.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,eAAe;EACf,cAAc;EACd,+BAA+B;EAC/B,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;;AAGA,mBAAmB;;;AAGnB;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".email-form-container {\n  width: 80%;\n  padding-bottom: 5rem;\n}\n\n.email-form-title {\n  margin-bottom: 1rem;\n  font-family: 'Open Sans', sans-serif;\n}\n\n.email-form-subtext {\n  margin-top: 0;\n  font-size: 16px;\n  color: #6B6B6B;\n  font-family: \"Lato\", sans-serif;\n  margin-bottom: 1.5rem;\n  padding: 0 5rem;\n}\n\n.email-form-input {\n  width: 100%;\n  padding: 1rem;\n  margin-bottom: 15px;\n  border: 1px solid #4eabe9;\n  border-radius: 4px;\n  min-height: 40px;\n  font-size: 16px;\n  font-weight: 500;\n}\n\n\n/* Mobile Styling */\n\n\n@media (max-width: 480px) {\n  .email-form-subtext{\n    padding: 0 .8rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
