

import React from 'react'
import { NextButton } from '../../NextButton'
import './SubscribePage.css';
import subsImg from '../../images/subs.png';
import { useSelector } from 'react-redux';

const SubscribePage = () => {

    const { processId } = useSelector(state => state.email);
    const { f_name } = useSelector(state => state.contact)
    const isFormValid = processId && f_name;

    const TableContent = [
        {
            "title": "Daily Opportunities -",
            "desc": "Receive a comprehensive list of new DIBB opportunities from your search criteria"
        },
        {
            "title": "Automated Data Extraction -",
            "desc": "All necessary solicitation information is extracted and organized for you."
        },
        {
            "title": "Excel File Delivery -",
            "desc": "Opportunities are delivered in an easy-to-use Excel format, with each opportunity in a separate tab."
        },
        {
            "title": "Save Time -",
            "desc": "Save 2 to 3 hours per day with the automated data processing "
        },
        {
            "title": "Member Support -",
            "desc": "Access to troubleshooting and support as long as you are a member."
        },
        {
            "title": "No Long-Term Commitment  -",
            "desc": "Cancel any Time"
        },
    ]


    return (
        <div className='container-subs'>
            <div className='first-col'>
                <div className='first-heading'>Free 14 Day Trial Then...<br /> $100 per Month - Save Time & Win More Awards<br />  GovScraper  Advantage - Daily Govt Opportunity List</div>
                <div>
                    <div>
                        {TableContent.map((content, index) => (
                            <div key={index}>
                                <div className='col-line'></div>
                                <div className='list'>
                                    <div className='list-title'>{content.title}</div>
                                    <div className='list-sub'>{content.desc}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <form action="https://api.govscraper.com/create-checkout-session" method="POST">
                        <input type="hidden" name="process_id" value={processId} />
                        <input type="hidden" name="name" value={f_name} />
                        <NextButton className="subscribe-screen-next-button" buttonText="Click Here To Subscribe" type="submit" disabled={!isFormValid}  />
                    </form>
                    {/* handleClick={onClickNext} */}
                </div>
            </div>
            <div className='second-col'>
                <img alt='not Found' src={subsImg} />
            </div>

        </div>



    )
}

export default SubscribePage