// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-codes-dropdown-container {
  width: 80%;
  padding-bottom: 5rem;
}

.select-codes-title {
  margin-bottom: 1rem;
  font-family: 'Lato', sans-serif;
}

.select-codes-subtext {
  margin-top: 0;
  color: #6B6B6B;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.select-codes-question {
  font-size: 16px;
  color: #6B6B6B;
  font-family: "Lato", sans-serif;
  font-weight: 550;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/StepsSection/SelectCodes/SelectCodes.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,cAAc;EACd,eAAe;EACf,+BAA+B;EAC/B,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,+BAA+B;EAC/B,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".select-codes-dropdown-container {\n  width: 80%;\n  padding-bottom: 5rem;\n}\n\n.select-codes-title {\n  margin-bottom: 1rem;\n  font-family: 'Lato', sans-serif;\n}\n\n.select-codes-subtext {\n  margin-top: 0;\n  color: #6B6B6B;\n  font-size: 16px;\n  font-family: \"Lato\", sans-serif;\n  font-weight: 700;\n  margin-bottom: 0.5rem;\n}\n\n.select-codes-question {\n  font-size: 16px;\n  color: #6B6B6B;\n  font-family: \"Lato\", sans-serif;\n  font-weight: 550;\n  margin-top: 3rem;\n  margin-bottom: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
