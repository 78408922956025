import './NavBarStyles.css';
import React from 'react';
// import { NextButton } from '../NextButton/index';
// import mobile from '../images/banner_svg/Banner Mobile 2(Tristan Thomas).svg';
// import tablet from '../images/banner_svg/Banner Tablet (Tristan Thomas).svg';
// import desktop from '../images/banner_svg/Banner (Tristan Thomas).svg';


export const NavBar = ({ activeStep }) => {
  const renderSteps = () => {
    return [1, 2, 3, 4, 5, 6].map((step) => (
      <div
        key={step}
        className={`circle ${activeStep === step ? 'active' : ''} ${step < activeStep ? 'disabled' : ''}`}
      >
        {step}
      </div>
    ));
  };

  return (
    <div>
      {/* {activeStep < 6 ? (<><div className="responsive-image">
          <img src={mobile} alt="Mobile Banner" className="mobile" />
          <img src={tablet} alt="Tablet Banner" className="tablet" />
          <img src={desktop} alt="Desktop Banner" className="desktop" />
        </div>
        <div className='login'>  <NextButton buttonText="Login" className='mt-0' handleClick={() => {}}/></div>

        </>

      ) : null} */}
      <div className="progress-bar">
        {renderSteps()}
      </div>
    </div>
  );
};
